import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ImagePaula from "../components/imagePaula"
import ImageVirginie from "../components/imageVirginie"
import ImageCyril from "../components/imageCyril"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

const EquipePage = () => (
  <Layout pathEn="/en/team/">
    <SEO
      title="Équipe - Dr Philippe Roure"
      description="Présentation de l'équipe Dr Philippe Roure, chirurgien du membre supérieur à Paris : Virginie et Paula sont assistantes médicales, Cyril est assistant opératoire."
    />
    <Helmet>
      <link
        rel="alternate"
        hreflang="en"
        href={`https://www.docteurphilipperoure.com/en/team/`}
      />
    </Helmet>
    <Wrapper>
      <H1>L'Équipe</H1>
      <P>
        <strong>Virginie</strong> et <strong>Paula</strong>, les deux
        assistantes du Dr Philippe Roure, vous accueilleront et vous aideront
        efficacement pour organiser votre intervention, vos examens
        complémentaires. Elles sont joignables par téléphone et pourront vous
        renseigner directement ou par l’intermédiaire du Dr Roure. Virginie est
        présente à l'
        <Link to="/consultation/">Espace Médical Vauban</Link>, Paula est
        présente à la <Link to="">Clinique du Sport Paris V</Link>.
      </P>
      <P>
        <strong>Cyril</strong> est infirmier diplômé d'état de bloc opératoire.
        Il assiste le Dr Roure au bloc opératoire dans les interventions qu'il
        pratique à la Clinique du Sport. C’est lui qui vous accueillera en salle
        opératoire avant votre intervention.
      </P>
      <P>
        Le Dr Roure exerce au sein de l’Espace Médical Vauban avec ses{" "}
        <strong>collègues chirurgiens et médecins</strong> spécialisés en
        orthopédie, traumatologie, et dans les différentes pathologies du sport
        et de l’arthrose. Au sein de l’
        <strong>unité de chirurgie de la main et du membre supérieur</strong> de
        l’Espace médical Vauban, le Dr Roure collabore avec le Dr Fontès.
        Ensemble, ils se concertent sur les cas les plus délicats et assurent le{" "}
        <strong>suivi de leurs patients</strong> de façon personnalisée.
      </P>
      <Row>
        <Col>
          <ImagePaula />
          <Legende>Paula</Legende>
          <Hr />
          <Metier>Assistante médicale</Metier>
        </Col>
        <Col>
          <ImageVirginie />
          <Legende>Viriginie</Legende>
          <Hr />
          <Metier>Assistante médicale</Metier>
        </Col>
        <Col>
          <ImageCyril />
          <Legende>Cyril</Legende>
          <Hr />
          <Metier>Assistant opératoire</Metier>
        </Col>
      </Row>
    </Wrapper>
  </Layout>
)

export default EquipePage

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 0.5em;
  padding: 0.5em 2em 1em 2em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em;
  }
`

const H1 = styled.h1`
  margin: 25px 0 25px;
`
const Row = styled.div`
  display: flex;
  margin-top: 25px;
  @media (max-width: 678px) {
    display: block;
  }
`

const Col = styled.div`
  flex: 33.3%;
  padding: 0 40px;
`

const Legende = styled.div`
  text-align: center;
  font-weight: 500;
  margin-top: 15px;
  letter-spacing: 0.04em;
  font-size: 1.23em;
`
const Hr = styled.hr`
  width: 18%;
  margin: 6px auto 2px;
  color: #4f5767;
`

const Metier = styled.div`
  text-align: center;
  font-size: 0.89em;
  color: #4f5767;
  @media (max-width: 678px) {
    margin-bottom: 2em;
  }
`

const P = styled.p`
  margin-bottom: 15px;
`
